import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import { graphql } from "gatsby"
import Img from "gatsby-image"

import '../styles/global.css';

export default ({ data }) => {
  return (
    <main>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <Header data={data} />
      <div class="content flex">
        <div class="left">
          <h1>Hi, I'm Nils. <span role="img" class="emoji" aria-label="waving hand">👋</span></h1>
          <p class="subheadline">
            Software developer, e-commerce specialist and entrepreneur.<br/>
            This website is my personal playground for new ideas.<br />
            Feel free to read my blog, follow me on GitHub or <a href="mailto:hello@aboutnils.com">contact me</a>.
          </p>
          <p class="references">
            <a href="https://www.linkedin.com/in/nils-buschhueter/" class="img-link" target="_blank" rel="noreferrer" alt="LinkedIn Profile" title="LinkedIn Profile"><Img fixed={data.linkedIn.childImageSharp.fixed} /></a>
            <a href="https://github.com/ConcurrentHashMap" class="img-link" target="_blank" rel="noreferrer" alt="GitHub Profile" title="GitHub Profile"><Img fixed={data.github.childImageSharp.fixed} /></a>
          </p>

          <h2>Latest posts</h2>
          <p><i>Nothing here yet.</i></p>
        </div>
      </div>
    </main>
  );
}

export const query = graphql`
  query {
    profilePic: file(relativePath: { eq: "nils.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    github: file(relativePath: { eq: "github.png" }) {
      childImageSharp {
        fixed(width: 30, quality: 100) {
          ...GatsbyImageSharpFixed
        } 
        
      }
    },
    linkedIn: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fixed(width: 34, quality: 100) {
          ...GatsbyImageSharpFixed
        } 
        
      }
    },
    site {
      siteMetadata {
        title
      }
    }
  }
`